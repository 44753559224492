import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import classes from "./Introduce.module.css";
import intro_jinseung from "../../assets/images/intro_jinseung.png";
function IntroducePage(props) {
    //  breadCrumb sample data
    const breadCrumbList = ["회사소개", "인사말"];
    return (
        <>
            <BreadCrumb breadCrumbList={breadCrumbList} />
            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <h3>인사말</h3>
                </div>
                <div className={classes.intro_box_wrap}>
                    <div className={classes.img_box}>
                        <img src={intro_jinseung} alt="진승 이미지" />
                    </div>
                    <div className={classes.txt_box}>
                        <h4>진승의 홈페이지 방문을 환영합니다. </h4>
                        <div>
                            ㈜진승인터내셔널은 1995년 설립되어 1996년 3월부터 현재까지 STORM
                            Products. INC.의 한국 공식 수입/유통 업체로서 한국 볼링 시장에 우수한
                            기술력으로 생산된 뛰어난 품질의 제품을 공급해왔습니다.
                        </div>
                        <div>
                            당사는 2005년 ROTOGRIP INC.와 한국 독점 대리점 계약 이 후에는 볼링가방,
                            볼링슈즈 등 다양한 볼링용품을 제조 및 판매하고 있으며, 2016년
                            레인정비기계 제조회사 KEGEL사와 한국 독점 계약, 2019년에는 레인 및
                            볼링설비 전문 회사 Qubica AMF와 공식 대리점 계약 및 자회사 ㈜JTS
                            설립으로 볼링과 관련된 모든 것을 취급하는 회사로 거듭나게 되었습니다.
                        </div>
                        <div>
                            볼링 저변 확대와 활성화를 위해 국가대표팀과 학생 선수들에게 용품을
                            지원하고 대회 개최하며, KPBA 스폰서로서 용품 및 투어 대회를 후원하고
                            있습니다. 또한, 볼링 동호인 여러분의 관심과 사랑에 보답하기 위해
                            고객감사 페스티벌과 함께 볼러분들이 즐길 수 있는 다양한 이벤트도
                            개최하고 있습니다.
                        </div>
                        <div>
                            ㈜진승인터내셔널은 볼러 여러분께서 보내주신 아낌없는 성원과 사랑을
                            바탕으로 볼링의 활성화와 한국 볼링 시장의 발전을 위해 최선을 다하며,
                            항상 볼러를 먼저 생각하는 마음을 잊지 않고 볼러와 함께 발전해 나아가는
                            회사가 되겠습니다.
                        </div>
                        <div>감사합니다.</div>
                        <div className={classes.ceo}>
                            <span>JINSEUNG CO., LTD</span>
                            <p>
                                대표이사 <b>전진표</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IntroducePage;
