import { Link, useLocation, useNavigate } from "react-router-dom";
import classes from "./FooterNavigation.module.css";
import SelectBox from "./UI/SelectBox/SelectBox";
import SelectBoxSearch from "./UI/SelectBox/SelectBoxNew";
import { useContext, useState } from "react";
import { JwtTokenContext } from "../store/JwtTokenProvider";

function FooterNavigation(props) {
    // ↓ ↓ 화면 구현을 위한 임시 장치 입니다.
    // isMain official home 상태의 footer 입니다.
    // const isMain = true;
    const location = useLocation();
    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();

    const isAdminUri = location.pathname.startsWith("/admin");
    const isTournamentUri = location.pathname.startsWith("/tournament");
    // ↓ ↓ admin 화면 확인을 위하여 상태를 수정하였습니다.
    const isAdmin = isAdminUri ? loginCtx.isAdmin() : false;

    const relatedSite = [
        {
            key: "mall",
            value: "진승몰 바로가기",
            url: "https://bowlingkoreamall.com/",
        },
        {
            key: "jts",
            value: "JTS",
            url: "https://www.qajts.com",
        },
        {
            key: "stom",
            value: "STORM/ROTO GRIP",
            url: "https://www.stormbowling.com",
        },
        {
            key: "bowlingAsso",
            value: "대한볼링협회",
            url: "http://bowling.or.kr",
        },
    ];
    const [categoryKey, setCategoryKey] = useState("");
    const setSelectItemCB = (optionItemKey) => {
        setCategoryKey(optionItemKey);

        const found = relatedSite.find((v) => v.key === optionItemKey);
        // 새 창으로 URL 열기
        window.open(found.url, "_blank");
    };

    const defaultMenu = () => {
        return (
            <ul>
                <li>
                    <Link to="/privatePolicy">개인정보처리방침</Link>
                </li>
                <li>
                    <Link to="/termsOfUse">이용약관</Link>
                </li>
                <li>
                    <Link to="/intro/location">찾아오시는 길</Link>
                </li>
                <li>
                    <Link to="/intro/introduce">회사소개</Link>
                </li>
            </ul>
        );
    };

    return (
        <>
            <footer className={classes.footer}>
                {isTournamentUri ? null : (
                    <div className={classes.product_wrap}>
                        <ul>
                            <li>storm</li>
                            <li>roto</li>
                            <li>master</li>
                            <li>3G</li>
                            <li>kegel</li>
                            <li>vise</li>
                        </ul>
                    </div>
                )}
                <div className={`${classes.footer_wrap} ${classes.main_footer}`}>
                    <h2 className={classes.footer_logo}>(주)진승</h2>
                    <div className={classes.related_site}>
                        <SelectBoxSearch
                            className="admin_st dark_mode"
                            optionDatas={relatedSite}
                            setSelectItemCB={setSelectItemCB}
                            showDefaultKey={categoryKey}
                        />
                    </div>
                    <div>
                        <nav className={classes.nav}>{defaultMenu()}</nav>
                        <div className={classes.info_company}>
                            <ul>
                                <li>사업자 등록번호 : 229-81-18231</li>
                                <li>통신판매업 신고번호 : 제 2004-130호</li>
                                <li>주소 : 경기도 용인시 기흥구 석성로 521번길 41(동백동)</li>
                            </ul>
                            <ul>
                                <li>대표자 : 전진표</li>
                                <li>대표전화 : 031-282-6011</li>
                                <li>개인정보 관리책임자 : 최일수</li>
                            </ul>
                            <div className={classes.copy_wrap}>
                                <p>Copyright JINSEUNG INTERNATIONAL. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default FooterNavigation;
